
export default {
  name: "MyEstaid",
  components: {},
  data() {
    return {
      menuItems: [
        {
          to: "/my-estaid/lists",
          title: this.$t("LISTS_HEADING"),
          icon: "mdi-bookmarks",
        },
      ],
    };
  },
  mounted() {
    // We don't have a placeholder for the /my-estaid/ so we route to /my-estaid/lists
    if (this.$route.fullPath === "/my-estaid/") {
      this.$router.push("/my-estaid/lists");
    }
  },
};
